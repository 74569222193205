<!--
 * @Author: 智客云网络科技
 * @Date: 2021-05-20 11:23:11
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:25
 * @Description: 创建 修改 仓库
 * @FilePath: \src\views\App\Storage\Warehouse\NUWarehouse.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item label="仓库名称：" prop="name" style="width: 45%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="仓库简称："
            prop="abbrev_name"
            style="width: 45%"
          >
            <el-input
              size="small"
              v-model="m.abbrev_name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="最大容量：" style="width: 45%">
            <el-input-number
              v-model="m.volume"
              size="small"
              :min="1"
              :max="99999999999"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="仓库状态：" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item
            label="仓库地址："
            prop="address"
            style="width: 90%"
            class="address-box"
          >
            <el-input
              size="small"
              v-model="m.address"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item
              label="描述："
              prop="notes"
              style="width: 90%; display: block"
            >
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateWarehouse()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateWarehouse()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import { createWarehouse, updateWarehouse, getWarehouseInfo } from "../api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        name: "", //仓库名称
        abbrev_name: "", //仓库简称
        volume: "", //最大容量
        status: 1, //仓库状态
        address: "", //仓库地址
        notes: "", //描述
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      kor_id: null, //组织ID
    };
  },
  methods: {
    // 打开
    open(id, kor_id = null) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.kor_id = kor_id; //组织ID
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "添加新仓库";
          this.loading = false;
        } else {
          //修改
          this._GetWarehouseInfo(id);
          this.title = "修改仓库信息";
        }
      });
    },

    //添加新仓库
    _CreateWarehouse() {
      var m = this.m;
      createWarehouse({
        kor_id: this.kor_id,
        name: m.name,
        abbrev_name: m.abbrev_name,
        volume: m.volume,
        status: m.status,
        address: m.address,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改仓库
    _UpdateWarehouse() {
      var m = this.m;
      updateWarehouse({
        kwh_id: this.id,
        name: m.name,
        abbrev_name: m.abbrev_name,
        volume: m.volume,
        status: m.status,
        address: m.address,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取仓库信息
    _GetWarehouseInfo(kwh_id) {
      getWarehouseInfo({ kwh_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.name = d.name;
          m.abbrev_name = d.abbrev_name;
          m.volume = d.max_volume;
          m.status = d.status;
          m.address = d.address;
          m.notes = d.notes;
          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>

<style>
.address-box .el-form-item__content {
  width: calc(100% - 100px);
}
</style>
